export default function AppIcon() {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.6066 0.150391C20.2447 0.150391 11.434 3.40527 7.94062 7.96426L23.8906 23.9121C26.1109 24.2691 28.3557 24.4523 30.6045 24.46C44.0365 24.46 54.9141 19.0201 54.9141 12.3041C54.9141 5.58809 44.0365 0.150391 30.6045 0.150391H30.6066Z"
        style={{ fill: 'var(--logo-primary)' }}
      />
      <path
        d="M54.9162 18.3842C54.9162 25.1002 44.0387 30.5379 30.6066 30.5379C28.6021 30.5379 26.6578 30.3875 24.8037 30.1447L17.2971 37.6492C21.1277 38.8953 25.6846 39.6537 30.6066 39.6537C44.0387 39.6537 54.9162 34.216 54.9162 27.5"
        style={{ fill: 'var(--logo-primary)' }}
      />
      <path
        d="M54.9162 33.5779C54.9162 40.2918 44.0387 45.7316 30.6066 45.7316C23.6801 45.7316 17.4496 44.2127 13.0131 41.9332L7.9084 47.0379C11.4039 51.5969 20.2447 54.8475 30.6066 54.8475C44.0387 54.8475 54.9162 49.4098 54.9162 42.6938"
        style={{ fill: 'var(--logo-primary)' }}
      />
      <path
        d="M4.31406 12.4588L0 16.7729L10.6348 27.4076L0 38.0445L4.31406 42.3629L19.2672 27.4098"
        style={{ fill: 'var(--logo-secondary)' }}
      />
    </svg>
  );
}
